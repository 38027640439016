import React, { useState } from 'react'

import { ApiResponse, mockApiResponse } from '../MockedResponse' // Correct import path

import styles from './AddressForm.module.scss'

// Chain options
const chainOptions = [
  { label: 'Select a chain', value: '' },
  { label: 'Ethereum (ETH)', value: 'ETH' },
]

// Wallet address options
const walletAddresses = [
  { label: 'Select an address', value: '' },
  {
    label: '0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a',
    value: '0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a',
  },
  {
    label: '0x3902768B8F7128788D2188f770255A4fc895E793',
    value: '0x3902768B8F7128788D2188f770255A4fc895E793',
  },
  {
    label: '0x3902768B8F7128788D2188f770255A4fc895E792',
    value: '0x3902768B8F7128788D2188f770255A4fc895E792',
  },
]

const AddressForm: React.FC<{ onMockApiResponse: (response: ApiResponse) => void }> = ({
  onMockApiResponse,
}) => {
  const [selectedChain, setSelectedChain] = useState<string>('')
  const [selectedAddress, setSelectedAddress] = useState<string>('')

  const handleSubmit = () => {
    const response = mockApiResponse(selectedChain, selectedAddress)
    onMockApiResponse(response)
  }

  return (
    <div className={styles.addressForm}>
      <div className={styles.formGroup}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="chainSelect" className={styles.label}>
          Blockchain:
        </label>
        <select
          id="chainSelect"
          value={selectedChain}
          onChange={(e) => setSelectedChain(e.target.value)}
          className={styles.select}
        >
          {chainOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formGroup}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="walletSelect" className={styles.label}>
          Wallet Address:
        </label>
        <select
          id="walletSelect"
          value={selectedAddress}
          onChange={(e) => setSelectedAddress(e.target.value)}
          className={styles.select}
        >
          {walletAddresses.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <button type="button" onClick={handleSubmit} className={styles.button}>
        Submit
      </button>
    </div>
  )
}

export default AddressForm
