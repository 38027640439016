interface ReportDetail1 {
  report_data: Array<{
    description: string
  }>
  owner_info: Array<{
    owner_info: Array<{
      name: string
      url: string
      legal_name: string
    }>
  }>
}

interface ReportDetail2 {
  blockchain: string
  address: string
  report_data: Array<{ description: string }>
  owner_info?: unknown
}

interface ScreeningReportData {
  screening_report: {
    blockchain?: string
    address?: string
    sanctioned_or_investigated: boolean
    details: Array<ReportDetail1 | ReportDetail2>
  }
}

interface MockResponse1 {
  api_version: string
  data: Partial<ScreeningReportData>
  errors?: {
    code: string
    message: string
  }
}

interface MockResponse2 {
  status: string
  message: string
  chain: string
  address: string
}

export type ApiResponse = MockResponse1 | MockResponse2

export const mockApiResponse = (chain: string, address: string): ApiResponse => {
  if (!chain) {
    return {
      api_version: '2.0',
      data: {},
      errors: {
        code: 'ZENCS-PARAMGET-AA5',
        message: 'chain not provided',
      },
    }
  }

  if (!address) {
    return {
      api_version: '2.0',
      data: {},
      errors: {
        code: 'ZENCS-PARAMGET-AA4',
        message: 'address not provided',
      },
    }
  }

  if (chain === 'ETH' && address === '0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a') {
    return {
      api_version: '2.0',
      data: {
        screening_report: {
          blockchain: 'ETH',
          address: '0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a',
          sanctioned_or_investigated: true,
          details: [
            {
              report_data: [
                {
                  description:
                    "The address listed on the US Treasury Department's Office of Foreign Assets Control sanction list.",
                },
              ],
              owner_info: [
                {
                  owner_info: [
                    {
                      name: 'Bittrex.com',
                      url: 'https://www.Bittrex.com',
                      legal_name: 'Bittrex Global GmbH',
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    }
  }

  if (chain === 'ETH' && address === '0x3902768B8F7128788D2188f770255A4fc895E793') {
    return {
      api_version: '2.0',
      data: {
        screening_report: {
          sanctioned_or_investigated: true,
          details: [
            {
              blockchain: 'ETH',
              address: '0x3902768B8F7128788D2188f770255A4fc895E793',
              report_data: [
                {
                  description:
                    'Address with significant part of transactions in close proximity to illicit activities',
                },
              ],
              owner_info: null,
            },
          ],
        },
      },
    }
  }

  if (chain === 'ETH' && address === '0x3902768B8F7128788D2188f770255A4fc895E792') {
    return {
      api_version: '2.0',
      data: {
        screening_report: {
          sanctioned_or_investigated: false,
          details: [
            {
              blockchain: 'ETH',
              address: '0x3902768B8F7128788D2188f770255A4fc895E792',
              report_data: [
                {
                  description: 'No risk indicator has been triggered for this address',
                },
              ],
              owner_info: null,
            },
          ],
        },
      },
    }
  }

  return {
    status: 'success',
    chain,
    address,
    message: `Mock response for chain ${chain} and wallet address ${address}`,
  }
}

export default mockApiResponse
