import React from 'react'

import BackgroundCheckTesting from 'components/BackgroundCheckTesting/BackgroundCheckTesting'
import Layout from 'components/Layout'

const BackgroundCheckTestingPage: React.FC = () => (
  <Layout>
    <BackgroundCheckTesting />
  </Layout>
)

export default BackgroundCheckTestingPage
