import React, { useEffect, useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import paths from 'constants/paths'
import IframeMessageStoreContext from 'contexts/IframeMessageStoreContext'
import CryptoTaxes from 'pages/CryptoTaxes'
import Home from 'pages/Home'
import IframeMessageLog from 'pages/IframeMessageLog'
import Settings from 'pages/Settings'
import IframeMessageStore from 'stores/IframeMessageStore'

import styles from './App.module.scss'
import SettingsStoreContext from './contexts/SettingsStoreContext'
import ApiTesting from './pages/ApiTesting'
import BackgroundCheckTesting from './pages/BackgroundCheckTesting'
import SettingsStore from './stores/SettingsStore'

const modifiedPaths = Object.entries(paths).reduce(
  (object, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    object[key] = value.replace(/^\//, '')
    return object
  },
  {} as { [_: string]: string },
) as typeof paths

const router = createBrowserRouter([
  {
    path: '/*',
    children: [
      {
        path: modifiedPaths.home,
        element: <Home />,
      },
      {
        path: modifiedPaths.cryptoTaxes,
        element: <CryptoTaxes />,
      },
      {
        path: modifiedPaths.iframeMessageLog,
        element: <IframeMessageLog />,
      },
      {
        path: modifiedPaths.settings,
        element: <Settings />,
      },
      {
        path: `${modifiedPaths.apiTesting}/*`,
        element: <ApiTesting />,
      },
      {
        path: modifiedPaths.backgroundCheckTesting,
        element: <BackgroundCheckTesting />,
      },
    ],
  },
])

function App() {
  const iframeMessageStore = useMemo(() => new IframeMessageStore(), [])
  const settingsStore = useMemo(() => new SettingsStore(), [])

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin === window.location.origin) return
        if (!event.data?.eventType) return

        iframeMessageStore.addMessage(event)
      },
      false,
    )
  }, [])

  return (
    <div className={styles.app}>
      <SettingsStoreContext.Provider value={settingsStore}>
        <IframeMessageStoreContext.Provider value={iframeMessageStore}>
          <RouterProvider router={router} />
        </IframeMessageStoreContext.Provider>
      </SettingsStoreContext.Provider>
    </div>
  )
}

export default App
