import React from 'react'
import { FaHome, FaNetworkWired } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { LuMessagesSquare } from 'react-icons/lu'
import { SiBitcoinsv } from 'react-icons/si'

import paths from 'constants/paths'
import { ReactComponent as Logo } from 'icons/logo.svg'

import styles from './index.module.scss'
import Link from './Link'

const Sidebar = () => (
  <div className={styles.sideBar}>
    <div className={styles.top}>
      <div className={styles.companyName}>
        <Logo />
        <span>ZenLedger Partner Console</span>
      </div>
      <Link to={paths.home}>
        <FaHome className={styles.linkIcon} /> Home
      </Link>
      <Link to={paths.cryptoTaxes}>
        <SiBitcoinsv className={styles.linkIcon} /> Crypto Taxes
      </Link>
      <Link to={paths.iframeMessageLog}>
        <LuMessagesSquare className={styles.linkIcon} /> iframe Message Log
      </Link>
      <Link to={paths.apiTesting}>
        <FaNetworkWired className={styles.linkIcon} /> Api Testing
      </Link>
      <Link to={paths.backgroundCheckTesting}>
        <FaNetworkWired className={styles.linkIcon} /> Background Check Testing
      </Link>
    </div>
    <div className={styles.bottom}>
      <Link to={paths.settings} className={styles.link} activeClassName={styles.active}>
        <FiSettings className={styles.linkIcon} /> Settings
      </Link>
    </div>
  </div>
)

export default Sidebar
