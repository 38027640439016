import React, { useState } from 'react'

import AddressForm from '../AddressForm'
import { ApiResponse } from '../MockedResponse'

import styles from './BackgroundCheckTesting.module.scss'

const BackgroundCheckTesting: React.FC = () => {
  const [response, setResponse] = useState<ApiResponse | null>(null)

  const handleMockApiResponse = (resp: ApiResponse) => {
    setResponse(resp)
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Background Check Testing</h1>
      <AddressForm onMockApiResponse={handleMockApiResponse} />
      {response && (
        <div className={styles.responseContainer}>
          <h3 className={styles.responseTitle}>Mock Response:</h3>
          <pre className={styles.responseContent}>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  )
}

export default BackgroundCheckTesting
